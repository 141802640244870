import { UPDATE_LANGUAGE } from "./action";

const defaultState = {
    lang:  localStorage.getItem('ymgweb-lang') && localStorage.getItem('ymgweb-lang') ? localStorage.getItem('ymgweb-lang') : "ZH_CN"
};

function i18nReducer(state = defaultState, action) {
    switch (action.type) {
        case UPDATE_LANGUAGE:
            return {
                ...state,
                lang: action.lang,
                // lang: state.lang,
            };
        default:
            return state;
    }
}

export default i18nReducer;