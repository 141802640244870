import React from "react";
import { Col, Row, Input } from "reactstrap";
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import { LazyLoadImage } from "react-lazy-load-image-component";

function AppointmentForm(props) {
    let errorsObj = {
        name: '',
        phoneNo: '',
        email: '',
        event: '',
        contactMethod: '',
        contactID: '',
        message: '',
    };
    const [errors, setErrors] = React.useState(errorsObj);
    const [contactMethod, setContactMethod] = React.useState('Wechat');
    const [formData, setFormData] = React.useState({
        name: '',
        phoneNo: '',
        email: '',
        subject: '',
        contactMethod: 'Wechat',
        contactID: '',
        message: '',
    })

    const subjectType = [
        { value: "马来西亚第二家园", label: "马来西亚第二家园" },
        { value: "工作签证", label: "工作签证" },
        { value: "房产", label: "房产" },
        { value: "其他", label: "其他" },
    ]

    const contactType = [
        { value: "WeChat", label: "WeChat" },
        { value: "Line", label: "Line" },
        { value: "Telegram", label: "Telegram" },
        { value: "Whatsapp", label: "Whatsapp" },
    ]

    const handleOptionSelected = async (e, type) => {
        const { value } = e.target;

        if (type === "contactMethod") {
            setContactMethod(value);
        }
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const newFormData = { ...formData };
        newFormData[name] = value;
        setFormData(newFormData);
    };

    function submitEmail() {
        let check = true;
        let errorMsg = { ...errorsObj };

        if (!formData.name) {
            errorMsg.name = "请填写此处！";
            check = false;
        }

        if (!formData.phoneNo) {
            errorMsg.phoneNo = "请填写此处！";
            check = false;
        }

        if (!formData.email) {
            errorMsg.email = "请填写此处！";
            check = false;
        }

        if (!formData.subject) {
            errorMsg.subject = "请选择一个项目！";
            check = false;
        }

        if (!formData.contactMethod) {
            errorMsg.contactMethod = "请填写此处！";
            check = false;
        }

        if (!formData.contactID) {
            errorMsg.contactID = "请填写此处！";
            check = false;
        }

        if (!formData.message) {
            errorMsg.message = "请填写此处！";
            check = false;
        }

        setErrors(errorMsg);

        if (check) {
            var formattedBody = 
            "姓名: " + formData.name + "\n" +
            "电话号码: " + formData.phoneNo + "\n" +
            "电邮地址: " + formData.email + "\n" +
            "项目: " + formData.subject + "\n" +
            "联络方式: " + formData.contactMethod + "\n" +
            "联络ID: " + formData.contactID + "\n" +
            "您的留言: " + formData.message + "\n";

            var mailToLink = "mailto:support@ymgmalaysia.com?body=" +  encodeURIComponent(formattedBody);
            window.location.href = mailToLink;
        }
    }

    return (
        <>
            <div className="appointmentBg min-height-100 bg-image-parent" id="appointmentForm">
            <LazyLoadImage src="assets/img/home/appointmentBg.webp" className="bg-image"/>


                <Row className="justify-content-center align-items-center mx-0">
                    <Col lg={10} md={12} sm={12} xs={12}>
                        <div className="appointmentform pt-5">
                            <Row className="justify-content-center align-items-center py-0 py-md-5">
                                <Col lg={9} md={10} sm={10} xs={10} className="mb-5 pb-4">
                                    <div className="text-start text-md-center">
                                        <span className="appointment-title">{translate(props.lang, "线上预约，与我们相见!")}</span>
                                    </div>
                                </Col>

                                <Col lg={5} md={5} sm={12} xs={12} className="mb-4">
                                    <div className="text-left px-4 px-md-0 px-lg-4">
                                        <span className="appointment-label" id="firstname">{translate(props.lang, "姓名")}</span>
                                        <div className="input-border mt-2 py-2">
                                            <input className="form-control border-none input-transparent"
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={(e) => handleAddFormChange(e)}
                                                aria-labelledby="firstname"
                                            />
                                        </div>
                                        <span className="errorTextRed mt-3">{translate(props.lang, errors.name)}</span>
                                    </div>
                                </Col>

                                <Col lg={5} md={5} sm={12} xs={12} className="mb-4">
                                    <div className="text-left px-4 px-md-0 px-lg-4">
                                        <span className="appointment-label " id="phoneNo">{translate(props.lang, "电话号码")}</span>
                                        <div className="input-border mt-2 py-2 ">
                                            <input className="form-control border-none input-transparent"
                                                type="text"
                                                name="phoneNo"
                                                value={formData.phoneNo}
                                                onChange={(e) => handleAddFormChange(e)}
                                                aria-labelledby="phoneNo"
                                            />
                                        </div>
                                        <span className="errorTextRed mt-3">{translate(props.lang, errors.phoneNo)}</span>
                                    </div>
                                </Col>

                                <Col lg={5} md={5} sm={12} xs={12} className="mb-4">
                                    <div className="text-left px-4 px-md-0 px-lg-4">
                                        <span className="appointment-label" id="email">{translate(props.lang, "电邮地址")}</span>
                                        <div className="input-border mt-2 py-2">
                                            <input className="form-control border-none input-transparent"
                                                type="text"
                                                name="email"
                                                value={formData.email}
                                                onChange={(e) => handleAddFormChange(e)}
                                                aria-labelledby="email"
                                            />
                                        </div>
                                        <span className="errorTextRed mt-3">{translate(props.lang, errors.email)}</span>
                                    </div>
                                </Col>

                                <Col lg={5} md={5} sm={12} xs={12} className="mb-4">
                                    <div className="text-left px-4 px-md-0 px-lg-4">
                                        <span className="appointment-label" id="project">{translate(props.lang, "项目")}</span>
                                        <div className="input-border mt-2 py-2">
                                            <Input
                                                type="select"
                                                name="sub"
                                                className="border-none px-2 input-transparent"
                                                value={formData.subject}
                                                onChange={(e) => handleOptionSelected(e, "subject")}
                                                aria-labelledby="project"

                                                >

                                                <option value="" disabled="disabled">{translate(props.lang, "选择项目")}</option>
                                                {subjectType && subjectType.map((item, i) => (
                                                    <option value={item.value} key={i}>{translate(props.lang, item.label)}</option>
                                                ))}
                                            </Input>
                                        </div>
                                        <span className="errorTextRed mt-3">{translate(props.lang, errors.subject)}</span>
                                    </div>
                                </Col>

                                <Col lg={5} md={5} sm={12} xs={12} className="mb-4">
                                    <div className="text-left px-4 px-md-0 px-lg-4">
                                        <span className="appointment-label" id="contact-method">{translate(props.lang, "联络方式")}</span>
                                        <div className="input-border mt-2 py-2">
                                            <Input
                                                type="select"
                                                name="contactMethod"
                                                className="border-none px-2 input-transparent"
                                                value={formData.contactMethod}
                                                onChange={(e) => handleOptionSelected(e, "contactMethod")}
                                                aria-labelledby="contact-method"
                                                >

                                                {contactType && contactType.map((item, i) => (
                                                    <option value={item.value} key={i}>{translate(props.lang, item.label)}</option>
                                                ))}
                                            </Input>
                                        </div>
                                        <span className="errorTextRed mt-3">{translate(props.lang, errors.contactMethod)}</span>
                                    </div>
                                </Col>

                                <Col lg={5} md={5} sm={12} xs={12} className="mb-4">
                                    <div className="text-left px-4 px-md-0 px-lg-4">
                                        <span className="appointment-label" id="contactID">{translate(props.lang, "您的")} {translate(props.lang, contactMethod)} ID</span>
                                        <div className="input-border mt-2 py-2">
                                            <input className="form-control border-none input-transparent"
                                                type="text"
                                                name="contactID"
                                                value={formData.contactID}
                                                onChange={(e) => handleAddFormChange(e)}
                                                aria-labelledby="contactID"
                                            />
                                        </div>
                                        <span className="errorTextRed mt-3">{translate(props.lang, errors.contactID)}</span>
                                    </div>
                                </Col>

                                <Col lg={10} md={10} sm={12} xs={12} className="mb-5">
                                    <div className="text-left px-4 px-md-0 px-lg-4">
                                        <span className="appointment-label" id="message">{translate(props.lang, "您的留言（可选）")}</span>
                                        <div className="mt-2">
                                            <Input
                                                type="textarea"
                                                rows="10"
                                                name="message"
                                                className="yourmessage-input input-border w-100 border-none px-4 py-2"
                                                value={formData.message}
                                                onChange={(e) => handleAddFormChange(e)}
                                                aria-labelledby="message"
                                            />
                                        </div>
                                        <span className="errorTextRed mt-3">{translate(props.lang, errors.message)}</span>
                                    </div>
                                </Col>

                                <Col lg={11} md={11} sm={12} xs={12}>
                                    <div className="submit-btn mx-auto" onClick={() => submitEmail()}>
                                        <span className="submit-txt">{translate(props.lang, "提交")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
      lang: i18n.lang
    }
  }
  export default connect(mapStateToProps)(AppointmentForm);