const EN = {
    "爱丽丝国际学校": "Alice Smith School",
    "英国吉隆坡国际学校": "The British International School Kuala Lumpur",
    "爱普森国际学校": "Epsom College in Malaysia",
    "花园国际学校": "Garden International School",
    "IGB国际学校": "IGB International School",
    "吉隆坡国际学校": "International School of Kuala Lumpur",
    "ISP国际学校": "The International School @ ParkCity",
    "满家乐国际学校": "Mont' Kiara International School",
    "莱仕国际学校": "Nexus International School",
    "腾比国际学校": "Tenby Schools",
    "泰莱国际学校": "Taylors International School",
    "绿洲国际学校": "Oasis International School",
}

export default EN;