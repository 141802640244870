import React, { useState } from "react";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import Marquee from "react-fast-marquee";
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import {  LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';

import fa1 from "../../assets/img/footer/fa1.webp";
import fa2 from "../../assets/img/footer/fa2.webp";
import fa3 from "../../assets/img/footer/fa3.webp";
import fa4 from "../../assets/img/footer/fa4.webp";
import fa5 from "../../assets/img/footer/fa5.webp";
import fa6 from "../../assets/img/footer/fa6.webp";
import fa7 from "../../assets/img/footer/fa7.webp";
import fa8 from "../../assets/img/footer/fa8.webp";
import fa9 from "../../assets/img/footer/fa9.webp";
import fa10 from "../../assets/img/footer/fa10.webp";

import fa1Selected from "../../assets/img/footer/fa1-selected.webp";
import fa2Selected from "../../assets/img/footer/fa2-selected.webp";
import fa3Selected from "../../assets/img/footer/fa3-selected.webp";
import fa4Selected from "../../assets/img/footer/fa4-selected.webp";
import fa5Selected from "../../assets/img/footer/fa5-selected.webp";
import fa6Selected from "../../assets/img/footer/fa6-selected.webp";
import fa7Selected from "../../assets/img/footer/fa7-selected.webp";
import fa8Selected from "../../assets/img/footer/fa8-selected.webp";
import fa9Selected from "../../assets/img/footer/fa9-selected.webp";
import fa10Selected from "../../assets/img/footer/fa10-selected.webp";

function DarkFooter(props) {
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const footerArr = [{ img: "/assets/img/footer/footerImg.webp" },
     { img: "/assets/img/footer/footerImg2.webp" },
     { img: "/assets/img/footer/footerImg3.webp" },
     { img: "/assets/img/footer/footerImg4.webp" },
     { img: "/assets/img/footer/footerImg5.webp" },
     { img: "/assets/img/footer/footerImg6.webp" },
     { img: "/assets/img/footer/footerImg7.webp" },
     { img: "/assets/img/footer/footerImg8.webp" },
     { img: "/assets/img/footer/footerImg9.webp" },
     { img: "/assets/img/footer/footerImg10.webp" },
     { img: "/assets/img/footer/footerImg11.webp" },
     { img: "/assets/img/footer/footerImg12.webp" },
     { img: "/assets/img/footer/footerImg13.webp" },
     { img: "/assets/img/footer/footerImg14.webp" },
     { img: "/assets/img/footer/footerImg15.webp" },
     { img: "/assets/img/footer/footerImg16.webp" },
     { img: "/assets/img/footer/footerImg17.webp" },
     { img: "/assets/img/footer/footerImg18.webp" }]

  const Redirect = (link) => {
    window.open(link, "_blank");
  }

  return (
    <footer className="footerbg bg-image-parent" data-background-color="black">
                <LazyLoadImage src="assets/img/footer/footerbg.webp" className="bg-image"/>


      <div className="row align-items-center justify-content-center mx-0" >
        <div className="col-12">
          <div className="text-center mt-3">
            <div>
              <span className="footer-title">
                {translate(props.lang, "构筑未来  启迪智慧")}
              </span>
            </div>
            <div>
              <span className="footer-title">
                {translate(props.lang, "搭建开发者与教育之桥")}
              </span>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="py-5">
            
            <Marquee>
            
              {footerArr.map((item, i) => (
                <div className="footerImg-margin" key={i}>
                  <LazyLoadImage src={item.img} className="mx-auto footer-img" alt="partner" />
                </div>
              ))}

            </Marquee>
          </div>
        </div>
        <div className="col-12">
          <div className="row justify-content-center pb-5">
            <div className="col-11 col-md-10">
              <div className="row align-items-start justify-content-center pb-5">
                <div className="col-12 col-lg-3 mb-3">
                  <div className="pb-3">
                  <LazyLoadComponent>

                    <img src="/assets/img/logo.webp" className="logoSize" alt="logo" />
                  </LazyLoadComponent>
                  </div>
                </div>
                <div className="col-12 col-lg-9 mb-4">
                  <div className="d-flex justify-content-around align-items-center w-100 footerPageInfo">
                    <div>
                      <a href="/home">
                        <span className={location.pathname === "/home" ? "active-nav-txt" : "footer-nav-txt"}>{translate(props.lang, "首页")}</span>
                      </a>
                    </div>
                    <div>
                      <a href="/about-us">
                        <span className={location.pathname === "/about-us" ? "active-nav-txt" : "footer-nav-txt"}>{translate(props.lang, "关于我们")}</span>
                      </a>
                    </div>
                    <div>
                      <a href="/longterm-pass">
                        <span className={location.pathname === "/longterm-pass" ? "active-nav-txt" : "footer-nav-txt"}>{translate(props.lang, "MM2H长期签证")}</span>
                      </a>
                    </div>
                    <div>
                      <a href="/property">
                        <span className={location.pathname.includes("property") ? "active-nav-txt" : "footer-nav-txt"}>{translate(props.lang, "房地产")}</span>
                      </a>
                    </div>
                    <div className="footer-eduDropdown px-0">
                      <Dropdown isOpen={dropdownOpen} toggle={toggle} className="h-100">
                        <DropdownToggle caret className="d-flex justify-content-center align-items-center h-100">
                          <span className={location.pathname.includes("education") ? "active-nav-txt px-1" : "footer-nav-txt px-1"}>{translate(props.lang, "教育")}</span>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem><a href="/education/application-flow"><span className={location.pathname.includes("application-flow") ? "active-nav-txt" : "footer-nav-txt"}>{translate(props.lang, "申请流程")}</span></a></DropdownItem>
                          <DropdownItem><a href="/education/international-schools"><span className={location.pathname.includes("international-school") ? "active-nav-txt" : "footer-nav-txt"}>{translate(props.lang, "国际学校")}</span></a></DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <div>
                      <a href="/contact-us">
                        <span className={location.pathname === "/contact-us" ? "active-nav-txt" : "footer-nav-txt"}>{translate(props.lang, "联系我们")}</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div>
                    <span className="footer-desc">{translate(props.lang, "易马海外成立于2014年，总部位于马来西亚首都【吉隆坡】，专业提供马来西亚房产、第二家园签证和国际留学咨询服务。我们秉承“客户为本、优质服务”的理念，为客户分析个人家庭需求，提供最适合的房产和家庭签证选择。以全面透明的服务为信念，让您安心地在马来西亚找到理想家园！多年来，易马海外家园已协助众多外籍家庭实现在马来西亚的美好生活。")}</span>
                  </div>
                </div>
                <div className="col-12 col-md-5">
                  <div className="mx-0 mx-md-4 mt-4 mt-md-0">
                    <div className="d-flex align-items-start">
                      <div>
                        <FaMapMarkerAlt className="mt-1 footerIcon" />
                      </div>
                      <div className="ml-3">
                        <span className="footer-txt">Suite 16-05 & 16-07, Level 16, GTower 199, Jalan Tun Razak 50400 Kuala Lumpur, Malaysia</span>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="d-flex align-items-start">
                        <FaPhoneAlt className="mt-1 footerIcon" />
                        <div className="ml-3">
                          <div>
                            <span className="footer-txt">{translate(props.lang, "电话热线")}  +60142133116</span>
                          </div>
                          <div>
                            <span className="footer-txt">{translate(props.lang, "电话")}  +60142133116</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">

                      <div className="d-flex align-items-center">
                        <div>
                          <FaEnvelope className="footerIcon" />
                        </div>
                        <div className="ml-3 break-white-space">
                          <span className="footer-txt break-white-space">marketing@ymgmalaysia.com</span>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-12 col-md-3 px-2 px-md-0">
                  <div className="mt-4 mt-md-0">
                    <span className="footer-txt font-weight-700">{translate(props.lang, "办公时间")}</span>
                  </div>
                  <div className="mt-2">
                    <span className="footer-txt">{translate(props.lang, "星期一 至 星期五 | 9:30 上午 - 6:30 下午")}</span>

                  </div>

                </div>
              </div>
              <div className="row align-items-center mt-1 mt-lg-5">
                <div className="col-12 col-md-7">
                  <div className="d-flex justify-content-center justify-content-md-start align-items-center" style={{ cursor: "pointer", flexFlow: "wrap" }}>
                    <div className="mx-2 my-2 my-md-2" onClick={(e) => Redirect("https://www.facebook.com/YMG.Life.my")}>
                    <LazyLoadComponent>

                      <img src={fa1}
                        onMouseOver={e => (e.currentTarget.src = fa1Selected)}
                        onMouseOut={e => (e.currentTarget.src = fa1)}
                        className="socialMediaIcon" alt="facebook"
                        />
                        </LazyLoadComponent>
                    </div>
                    <div className="mx-2 my-2 my-md-2" onClick={(e) => Redirect("https://www.instagram.com/ymglife/")} style={{ cursor: "pointer" }}>

                    <LazyLoadComponent>

                      <img src={fa2}
                        onMouseOver={e => (e.currentTarget.src = fa2Selected)}
                        onMouseOut={e => (e.currentTarget.src = fa2)}
                        className="socialMediaIcon" alt="instagram"
                        />
                        </LazyLoadComponent>
                    </div>
                    <div className="mx-2 my-2 my-md-2" onClick={(e) => Redirect("https://www.youtube.com/@YMG")} style={{ cursor: "pointer" }}>
                    <LazyLoadComponent>

                      <img src={fa3}
                        onMouseOver={e => (e.currentTarget.src = fa3Selected)}
                        onMouseOut={e => (e.currentTarget.src = fa3)}
                        className="socialMediaIcon" alt="youtube"
                        />
                        </LazyLoadComponent>
                    </div>
                    <div className="mx-2 my-2 my-md-2" onClick={(e) => Redirect("https://v.douyin.com/iJQnb4ea/")} style={{ cursor: "pointer" }}>
                    <LazyLoadComponent>


                      <img src={fa4}
                        onMouseOver={e => (e.currentTarget.src = fa4Selected)}
                        onMouseOut={e => (e.currentTarget.src = fa4)}
                        className="socialMediaIcon" alt="douyin"
                        />
                        </LazyLoadComponent>
                    </div>
                    <div className="mx-2 my-2 my-md-2" onClick={(e) => Redirect("https://www.linkedin.com/company/ymg-group/")} style={{ cursor: "pointer" }}>
                    <LazyLoadComponent>


                      <img src={fa5}
                        onMouseOver={e => (e.currentTarget.src = fa5Selected)}
                        onMouseOut={e => (e.currentTarget.src = fa5)}
                        className="socialMediaIcon" alt="linkedin"
                        />
                        </LazyLoadComponent>
                    </div>
                    <div className="mx-2 my-2 my-md-2" onClick={(e) => Redirect("https://www.ixigua.com/home/3078254550327055")} style={{ cursor: "pointer" }}>
                    <LazyLoadComponent>


                      <img src={fa6}
                        onMouseOver={e => (e.currentTarget.src = fa6Selected)}
                        onMouseOut={e => (e.currentTarget.src = fa6)}
                        className="socialMediaIcon" alt="ixigua"
                        />
                        </LazyLoadComponent>
                    </div>
                    <div className="mx-2 my-2 my-md-2" onClick={(e) => Redirect("https://profile.zjurl.cn/rogue/ugc/profile/?user_id=3078254550327055&request_source=1")} style={{ cursor: "pointer" }}>
                    <LazyLoadComponent>


                      <img src={fa7}
                        onMouseOver={e => (e.currentTarget.src = fa7Selected)}
                        onMouseOut={e => (e.currentTarget.src = fa7)}
                        className="socialMediaIcon" alt="toutiao"
                        />
                        </LazyLoadComponent>
                    </div>
                    <div className="mx-2 my-2 my-md-2" onClick={(e) => Redirect("https://www.xiaohongshu.com/user/profile/5f7be6160000000001007e5c")} style={{ cursor: "pointer" }}>
                    <LazyLoadComponent>


                      <img src={fa8}
                        onMouseOver={e => (e.currentTarget.src = fa8Selected)}
                        onMouseOut={e => (e.currentTarget.src = fa8)}
                        className="socialMediaIcon" alt="xhs"
                        />
                        </LazyLoadComponent>
                    </div>
                    <div className="mx-2 my-2 my-md-2" onClick={(e) => Redirect("https://m.weibo.cn/p/1006067289345368/photos?jumpfrom=weibocom&from=page_100606")} style={{ cursor: "pointer" }}>
                    <LazyLoadComponent>


                      <img src={fa9}
                        onMouseOver={e => (e.currentTarget.src = fa9Selected)}
                        onMouseOut={e => (e.currentTarget.src = fa9)}
                        className="socialMediaIcon" alt="weibo"
                        />
                        </LazyLoadComponent>
                    </div>
                    <div className="mx-2 my-2 my-md-2" onClick={(e) => Redirect("https://space.bilibili.com/695922895")} style={{ cursor: "pointer" }}>
                    <LazyLoadComponent>


                      <img src={fa10}
                        onMouseOver={e => (e.currentTarget.src = fa10Selected)}
                        onMouseOut={e => (e.currentTarget.src = fa10)}
                        className="socialMediaIcon" alt="bilibili"
                        />
                        </LazyLoadComponent>
                    </div>
                  </div>

                </div>
                <div className="col-12 col-md-5">
                  <div className="text-center text-md-start mt-4 mt-md-0">
                    <span className="copyright break-white-space">{translate(props.lang, "Copyright ©YMG (MM2H) SDN BHD 201801031818 (1293843-A) All Rights Reserved.")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

const mapStateToProps = state => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(DarkFooter);