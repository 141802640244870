import React from "react";
import { Col, Row } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';

function CustomerFeedback(props) {
  const [imageIndex2, setImageIndex2] = React.useState(0);
  const slider = React.useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    lazyLoad: "progressive",
    initOnLoad: true,
    nextSlidesToPreload: 5,
    autoplay: false,
    speed: 300,
    slidesToShow: window.innerWidth > 992 ? 3 : window.innerWidth > 677 ? 1 : 1,
    swipe: false,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settings2 = {
    dots: false,
    infinite: true,
    swipe: false,
    lazyLoad: "ondemand",
    initOnLoad: true,
    nextSlidesToPreload: 1,
    autoplay: true,
    speed: 300,
    slidesToShow:
      window.innerWidth > 992 ? 2 : window.innerWidth > 677 ? 2 : 1.2,
    draggable: false,
    centerPadding: 0,
    beforeChange: (current, next) => {
      setImageIndex2(next);
    },
  };

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <LazyLoadImage
          src="assets/img/home/right.webp"
          className="nextArrow"
          alt="next"
        />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <LazyLoadImage
          src="/assets/img/home/left.webp"
          className="prevArrow"
          alt="prev"
        />
      </div>
    );
  }

  return (
    <>
      <div className="customerFeedback min-height-100">
        <div className="customerFeedbackBg position-relative">
          <Row className="justify-content-center justify-content-lg-start align-items-start mx-0 h-100 position-relative">
            <Col lg={5} md={11} sm={11} xs={11} className="mb-5">
              <Row className="justify-content-start">
                <Col lg={9} md={8} sm={12} xs={12}>
                  <div className="text-left px-0 px-lg-5 py-4 py-md-5">
                    <div className="customerFeedbackTitle text-left ml-3">
                      <div className="">
                        <div>
                          <span className="d-block mb-1">{translate(props.lang, "客户见证")}</span>
                        </div>
                        <div className="">
                          <span className="d-block mb-4">{translate(props.lang, "感动之言")}</span>
                        </div>
                      </div>
                    </div>
                    <div className="ml-3">
                      <span className="d-block mb-3 futureBgDesc">
                        {translate(props.lang, "家人们对易马海外的真实见证和反馈，分享了他们在马来西亚第二家园计划、房产和国际留学等方面的愉快经历和成功故事，为您提供更深入的了解和信心。")}
                      </span>
                    </div>
                    <div className="">
                      <div className="d-flex align-items-center position-relative">
                        <LazyLoadImage
                          src={
                            "/assets/img/home/left.webp"
                          }
                          alt="left"
                          onClick={() => slider?.current?.slickPrev()}
                          style={{ cursor: "pointer" }}
                          className="leftSliderImg"
                        />
                        <LazyLoadImage
                          src={
                            "assets/img/home/right.webp"
                          }
                          alt="right"
                          onClick={() => slider?.current?.slickNext()}
                          style={{ cursor: "pointer" }}
                          className="rightSliderImg"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={7} md={12} sm={12} xs={12} className="mb-5">
              <Row className="justify-content-end">
                <Col lg={12} md={9} sm={10} xs={10} className="px-0">
                  <div className="customerFeedbackSliderBg"></div>
                </Col>
              </Row>
              <div className="position-absolute customerFeedbackSlider">
                <Slider {...settings2} ref={slider} arrows={false}>
                  <div
                    className={`${imageIndex2 === 0
                      ? "customerFeedbackCard  active"
                      : "customerFeedbackCard inactive"
                      }`}
                  >
                    <Row className="justify-content-center align-items-center py-5 cardMinHeight">
                      <Col lg={8} md={8} sm={8} xs={8} className="py-4">
                        <div className="pb-4">
                          <LazyLoadImage
                            src="assets/img/home/dot.webp"
                            alt="dot"
                            className="dot"
                          />
                        </div>
                        <div>
                          <span className="feedback-desc">
                            {translate(props.lang, "马来西亚第二家园计划真是太棒了！")}
                            {translate(props.lang, "我们全家都轻松获得了签证，并享受着美好的生活。谢谢您们的专业服务！")}
                          </span>
                        </div>
                        <div className="mt-2 mt-md-5">
                          <span className="feedback-name">{translate(props.lang, "张先生")}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className={`${imageIndex2 === 1
                      ? "customerFeedbackCard active"
                      : "customerFeedbackCard inactive"
                      }`}
                  >
                    <Row className="justify-content-center align-items-center py-5 cardMinHeight">
                      <Col lg={8} md={8} sm={8} xs={8} className="py-4">
                        <div className="pb-4">
                          <LazyLoadImage
                            src={
                              "assets/img/home/dot.webp"
                            }
                            alt="dot"
                            className="dot"
                          />
                        </div>
                        <div>
                          <span className="feedback-desc">
                            {translate(props.lang, "感谢团队在整个申请过程中的耐心指导和帮助。现在，我们已经在马来西亚开始新的生活，一切都很顺利！")}
                          </span>
                        </div>
                        <div className="mt-2 mt-md-5">
                          <span className="feedback-name">{translate(props.lang, "张女士")}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className={`${imageIndex2 === 2
                      ? "customerFeedbackCard active"
                      : "customerFeedbackCard inactive"
                      }`}
                  >
                    <Row className="justify-content-center align-items-center py-5 cardMinHeight">
                      <Col lg={8} md={8} sm={8} xs={8} className="py-4">
                        <div className="pb-4">
                          <LazyLoadImage
                            src={
                              "assets/img/home/dot.webp"
                            }
                            alt="dot"
                            className="dot"
                          />
                        </div>
                        <div>
                          <span className="feedback-desc">
                            {translate(props.lang, "马来西亚第二家园计划真是太棒了！")}
                            {translate(props.lang, "我们全家都轻松获得了签证，并享受着美好的生活。谢谢您们的专业服务！")}
                          </span>
                        </div>
                        <div className="mt-2 mt-md-5">
                          <span className="feedback-name">{translate(props.lang, "张先生")}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className={`${imageIndex2 === 3
                      ? "customerFeedbackCard active"
                      : "customerFeedbackCard inactive"
                      }`}
                  >
                    <Row className="justify-content-center align-items-center py-5 cardMinHeight">
                      <Col lg={8} md={8} sm={8} xs={8} className="py-4">
                        <div className="pb-4">
                          <LazyLoadImage
                            src={
                              "assets/img/home/dot.webp"
                            }
                            alt="dot"
                            className="dot"
                          />
                        </div>
                        <div>
                          <span className="feedback-desc">
                            {translate(props.lang, "感谢团队在整个申请过程中的耐心指导和帮助。现在，我们已经在马来西亚开始新的生活，一切都很顺利！")}
                          </span>
                        </div>
                        <div className="mt-2 mt-md-5">
                          <span className="feedback-name">{translate(props.lang, "张女士")}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Slider>
              </div>
            </Col>
          </Row>
        </div>

        <Row
          className="justify-content-center align-items-center mx-0 customerSlider"
          id="customerSlider"
        >
          <Col lg={12} md={11} sm={12} xs={12} className="px-3 px-lg-0">
            <Slider {...settings} arrows={true}>

              {
                localStorage && localStorage.getItem("YMG_location") === "China" ?
                  <div className="text-center videoSlider">
                    <LazyLoadComponent>
                    <iframe loading="lazy" src="//player.bilibili.com/player.html?aid=427779750&bvid=BV1i3411u7mV&cid=756889542&page=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
</LazyLoadComponent>
                 
                  </div>
                  :
                  < div className="text-center videoSlider">
                    <LazyLoadComponent>

                    <iframe loading="lazy" src="https://www.youtube.com/embed/irdkWMPoSe8?si=3hm9y48rcpVMtXCg&amp;modestbranding=1&rel=0&showinfo=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </LazyLoadComponent>
                  </div>
              }

              {
                localStorage && localStorage.getItem("YMG_location") === "China" ?
                  <div className="text-center videoSlider">
                    <LazyLoadComponent>
                    <iframe loading="lazy" src="//player.bilibili.com/player.html?aid=531681557&bvid=BV17u411H7mz&cid=1218458747&p=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
</LazyLoadComponent>
</div>
                  :
                  < div className="text-center videoSlider">
                  <LazyLoadComponent>
                  <iframe loading="lazy" src="https://www.youtube.com/embed/2eCxMrxcqrg?si=lHThDbNmnVPPU38T" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</LazyLoadComponent>
</div>
              }

              {
                localStorage && localStorage.getItem("YMG_location") === "China" ?
                  <div className="text-center videoSlider">
                    <LazyLoadComponent>
                    <iframe loading="lazy" src="//player.bilibili.com/player.html?aid=740434125&bvid=BV1yk4y1J7DM&cid=1108246742&p=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
</LazyLoadComponent>
</div>
                  :
                  < div className="text-center videoSlider">
                  <LazyLoadComponent>
                  <iframe loading="lazy" src="https://www.youtube.com/embed/NUrbr91x-a8?si=qexrWe2s4CU4Sr1I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</LazyLoadComponent>
</div>
              }

              {
                localStorage && localStorage.getItem("YMG_location") === "China" ?
                  <div className="text-center videoSlider">
                    <LazyLoadComponent>
                    <iframe loading="lazy" src="//player.bilibili.com/player.html?aid=995678225&bvid=BV1Gs4y1X7Em&cid=1117736353&p=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
</LazyLoadComponent>
</div>
                  :
                  < div className="text-center videoSlider">
                  <LazyLoadComponent>
                  <iframe loading="lazy" src="https://www.youtube.com/embed/wrzDlLknaUs?si=sprXvnYmmenXZTX1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</LazyLoadComponent>
</div>
              }



            </Slider>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(CustomerFeedback);