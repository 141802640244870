import React from "react";
import { Col, Row } from "reactstrap";
import { HashLink } from 'react-router-hash-link';
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import { LazyLoadComponent } from "react-lazy-load-image-component";

function PerfectDesc(props) {

    return (
        <>
            <div className="perfectBg-outer">
                <div className="perfectBg">
                <LazyLoadComponent>
                    <div className="bg-image" />
                </LazyLoadComponent>
                    <Row className="justify-content-center align-items-center h-100 mx-0">
                        <Col lg={7} md={8} sm={11} xs={11}>
                            <div className="text-center">
                                <div className="d-flex flex-column flex-md-row justify-content-center">
                                    <div>
                                        <span className="perfect-title">{translate(props.lang, "打造完美体验")}</span>
                                    </div>
                                    <div className="ml-0 ml-md-4">
                                        <span className="perfect-title">{translate(props.lang, " 助力无限可能")}</span>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <span className="perfect-desc">{translate(props.lang, "在追寻马来西亚第二家园（MM2H）的过程中，您无需担心繁琐的申请步骤。我们将全程协助您，确保您的体验轻松顺利。您只需专注于探索美妙的马来西亚，我们会确保您的MM2H申请过程轻松愉快，让您无后顾之忧。让我们携手打开探索马来西亚第二家园的大门，迎接新的未来！")}</span>
                                </div>

                                <div className="startJourneyBtnPos">
                                    <HashLink smooth to='/index#appointmentForm' className="hashLinkStyle submit-btn mx-auto">
                                        <span className="submit-txt">{translate(props.lang, "马上启程")}</span>
                                    </HashLink>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(PerfectDesc);
