import React from "react";
import HomeHeader from "./home/HomeHeader.js";
import SecondHome from "./home/SecondHome.js";
import VideoSection from "./home/VideoSection.js";
import MM2HPlan from "./home/MM2HPlan.js";
import CustomerFeedback from "./home/CustomerFeedback.js";
import AppointmentForm from "./home/AppointmentForm.js";
import PerfectDesc from "./home/PerfectDesc.js";
import RecentPost from "./home/RecentPost.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function Home() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <IndexNavbar />
      <div className="home">
        <HomeHeader />
        <SecondHome />
        
        <VideoSection />
        <MM2HPlan />
        <CustomerFeedback />
        <AppointmentForm />
        <PerfectDesc />
        <RecentPost />
      </div>
      <DarkFooter />
    </>
  );
}

export default Home;
