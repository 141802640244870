import React from "react";
import { Col, Row } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaCalendar, FaEye } from 'react-icons/fa';
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";

function RecentPost(props) {
    // const [selectedTab, setSelectedTab] = React.useState(0);
    const settings = {
        dots: false,
        infinite: false,
        lazyLoad: 'ondemand',
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 992 ? 3 : window.innerWidth > 677 ? 2 : 1.2,
        centerMode: false,
        centerPadding: 0,
    };
    return (
        <>
            <div className="recentNewsBg">
            <LazyLoadComponent>
                    <div className="bg-image" />
                </LazyLoadComponent>
                <Row className="justify-content-center align-items-center mx-0">
                    <Col lg={5} md={8} sm={11} xs={11} className="mb-4">
                        <div className="text-center mb-4">
                            <span className="news-title">{translate(props.lang, "最近贴文")}</span>
                        </div>
                    </Col>

                    <Col lg={10} md={11} sm={12} xs={12} className="px-0 mb-5">
                        <div className="d-none d-md-block">
                            <Row className="justify-content-center align-items-start py-5">
                                <Col lg={6} md={11} sm={11} xs={11} className="mb-4">
                                    <div className="bigNewsContainer ">
                                        <div>
                                            <LazyLoadImage src="assets/img/home/recentPost.webp" className="recent-img mb-4" alt="news1" />
                                            <span className="recent-title d-block mb-2">{translate(props.lang, "易马海外随旅游局、二家协会出访香港，成功举办大马二家推介会")}</span>
                                            <span className="recent-desc">{translate(props.lang, "随着2022年底中国出入境限制的逐步放开，大批中国香港人率先出境到日本、韩国、东南亚等地旅游...")}</span>
                                        </div>
                                        <hr style={{ background: "#5B6D91" }} />
                                        <Row className="justify-content-between align-items-center">
                                            <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                                <div className="d-flex align-items-center">
                                                    <FaEye color={"white"} className="small-txt" />
                                                    <div className="ml-3">
                                                        <span className="small-txt">4514 {translate(props.lang, "views")}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                                <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                                                    <FaCalendar color={"white"} className="small-txt" />
                                                    <div className="ml-2">
                                                        <span className="small-txt">17 January 2023</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={6} md={11} sm={11} xs={11} className="mb-4 px-0 px-md-2">
                                    <div className="sideNewsContainer ">
                                        <Row className="justify-content-center align-items-start ">
                                            <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                                <div>
                                                    <LazyLoadImage src="assets/img/home/recentPost2.webp" className="recent-img" alt="news2" />
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                                <span className="recent-title2 d-block mb-2">{translate(props.lang, "预订量激增！携程公布中国人今年春节出境游目的地TOP10，附马来西亚最新入境要求！")}</span>
                                                <span className="recent-desc2">{translate(props.lang, "三年的漫长等待终于迎来了曙光, 中国关闭边境超过3年...")}</span>
                                                <hr style={{ background: "#5B6D91" }} />
                                                <Row className="justify-content-between align-items-center">
                                                    <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                                        <div className="d-flex align-items-center">
                                                            <FaEye color={"white"} className="small-txt2" />
                                                            <div className="ml-3">
                                                                <span className="small-txt2">4514 {translate(props.lang, "views")}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} md={6} sm={11} xs={11} className="mb-4 ">
                                                        <div className="d-flex align-items-center justify-content-start justify-content-md-center">
                                                            <FaCalendar color={"white"} className="small-txt2" />
                                                            <div className="ml-3">
                                                                <span className="small-txt2">17 January 2023</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center align-items-start">
                                            <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                                <LazyLoadImage src="assets/img/home/recentPost3.webp" className="recent-img" alt="news3" />
                                            </Col>
                                            <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                                <span className="recent-title2 d-block mb-2">{translate(props.lang, "易马海外随行马来西亚旅游局到访台湾举办马来西亚第二家园推介会，业界热情高涨")}</span>
                                                <span className="recent-desc2">{translate(props.lang, "马来西亚观光局举办台湾业者推介会，首站12日在高雄展览馆登场，邀请南部旅游相关业者与会，盼藉由业者推介会深...")}</span>
                                                <hr style={{ background: "#5B6D91" }} />
                                                <Row className="justify-content-between align-items-center">
                                                    <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                                        <div className="d-flex align-items-center">
                                                            <FaEye color={"white"} className="small-txt2" />
                                                            <div className="ml-3">
                                                                <span className="small-txt2">4514 {translate(props.lang, "views")}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} md={6} sm={11} xs={11} className="mb-4 ">
                                                        <div className="d-flex align-items-center justify-content-start justify-content-md-center">
                                                            <FaCalendar color={"white"} className="small-txt2" />
                                                            <div className="ml-3">
                                                                <span className="small-txt2">17 January 2023</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center align-items-start">
                                            <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                                <LazyLoadImage src="assets/img/home/recentPost4.webp" className="recent-img" alt="news4" />
                                            </Col>
                                            <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                                <span className="recent-title2 d-block mb-2">{translate(props.lang, "大选后，马来西亚留学会有哪些政策变化？")}</span>
                                                <span className="recent-desc2">{translate(props.lang, "这场被称为“史上最激烈”的大选尘埃落定后，我们也看到了新任首相安华想要做出改变的决心。大选后，马来西亚留...")}</span>
                                                <hr style={{ background: "#5B6D91" }} />
                                                <Row className="justify-content-between align-items-center">
                                                    <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                                        <div className="d-flex align-items-center">
                                                            <FaEye color={"white"} className="small-txt2" />
                                                            <div className="ml-3">
                                                                <span className="small-txt2">4514 {translate(props.lang, "views")}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} md={6} sm={11} xs={11} className="mb-4 ">
                                                        <div className="d-flex align-items-center justify-content-start justify-content-md-center">
                                                            <FaCalendar color={"white"} className="small-txt2" />
                                                            <div className="ml-3">
                                                                <span className="small-txt2">17 January 2023</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="d-block d-md-none">
                            <Slider {...settings} arrows={false}>
                                <div className="bigNewsContainer px-2">
                                    <div>
                                        <LazyLoadImage src="assets/img/home/recentPost.webp" className="recent-img mb-4" alt="news1" />
                                        <span className="recent-title d-block mb-2">{translate(props.lang, "易马海外随旅游局、二家协会出访香港，成功举办大马二家推介会")}</span>
                                        <span className="recent-desc">{translate(props.lang, "随着2022年底中国出入境限制的逐步放开，大批中国香港人率先出境到日本、韩国、东南亚等地旅游...")}</span>
                                    </div>
                                    <hr style={{ background: "#5B6D91" }} />
                                    <Row className="justify-content-between align-items-center">
                                        <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                            <div className="d-flex align-items-center">
                                                <FaEye color={"white"} />
                                                <div className="ml-2">
                                                    <span className="small-txt">4514 {translate(props.lang, "views")}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                            <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                                                <FaCalendar color={"white"} />
                                                <div className="ml-2">
                                                    <span className="small-txt">17 January 2023</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="bigNewsContainer px-2">
                                    <div>
                                        <LazyLoadImage src="assets/img/home/recentPost2.webp" className="recent-img mb-4" alt="news2" />
                                        <span className="recent-title d-block mb-2">{translate(props.lang, "预订量激增！携程公布中国人今年春节出境游目的地TOP10，附马来西亚最新入境要求！")}</span>
                                        <span className="recent-desc">{translate(props.lang, "三年的漫长等待终于迎来了曙光, 中国关闭边境超过3年...")}</span>
                                    </div>
                                    <hr style={{ background: "#5B6D91" }} />
                                    <Row className="justify-content-between align-items-center">
                                        <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                            <div className="d-flex align-items-center">
                                                <FaEye color={"white"} />
                                                <div className="ml-2">
                                                    <span className="small-txt">4514 {translate(props.lang, "views")}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                            <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                                                <FaCalendar color={"white"} />
                                                <div className="ml-2">
                                                    <span className="small-txt">17 January 2023</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="bigNewsContainer px-2">
                                    <div>
                                        <LazyLoadImage src="assets/img/home/recentPost3.webp" className="recent-img mb-4" alt="news3" />
                                        <span className="recent-title d-block mb-2">{translate(props.lang, "易马海外随行马来西亚旅游局到访台湾举办马来西亚第二家园推介会，业界热情高涨")}</span>
                                        <span className="recent-desc">{translate(props.lang, "马来西亚观光局举办台湾业者推介会，首站12日在高雄展览馆登场，邀请南部旅游相关业者与会，盼藉由业者推介会深...")}</span>
                                    </div>
                                    <hr style={{ background: "#5B6D91" }} />
                                    <Row className="justify-content-between align-items-center">
                                        <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                            <div className="d-flex align-items-center">
                                                <FaEye color={"white"} />
                                                <div className="ml-2">
                                                    <span className="small-txt">4514 {translate(props.lang, "views")}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                            <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                                                <FaCalendar color={"white"} />
                                                <div className="ml-2">
                                                    <span className="small-txt">17 January 2023</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="bigNewsContainer px-2">
                                    <div>
                                        <LazyLoadImage src="assets/img/home/recentPost4.webp" className="recent-img mb-4" alt="news4" />
                                        <span className="recent-title d-block mb-2">{translate(props.lang, "大选后，马来西亚留学会有哪些政策变化？")}</span>
                                        <span className="recent-desc">{translate(props.lang, "这场被称为“史上最激烈”的大选尘埃落定后，我们也看到了新任首相安华想要做出改变的决心。大选后，马来西亚留...")}</span>
                                    </div>
                                    <hr style={{ background: "#5B6D91" }} />
                                    <Row className="justify-content-between align-items-center">
                                        <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                            <div className="d-flex align-items-center">
                                                <FaEye color={"white"} />
                                                <div className="ml-2">
                                                    <span className="small-txt">4514 {translate(props.lang, "views")}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} sm={11} xs={11} className="mb-4">
                                            <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                                                <FaCalendar color={"white"} />
                                                <div className="ml-2">
                                                    <span className="small-txt">17 January 2023</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(RecentPost);