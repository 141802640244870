import React from "react";
import { Col, Row } from "reactstrap";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';

function VideoSection(props) {
    return (
        <>
            <div className="min-height-100 videoSection">
                {
                    localStorage && localStorage.getItem("YMG_location") === "China" ?
                        <div className="">
                                    <LazyLoadComponent>
                            <iframe width="100%" loading="lazy" src="//player.bilibili.com/player.html?aid=469219675&bvid=BV1a5411R77v&cid=724956386&page=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
                            </LazyLoadComponent>

                        </div>
                        :
                        <div className="">
                            <LazyLoadComponent>
                            <iframe width="100%" loading="lazy" src="https://www.youtube.com/embed/6FpioUf6Y4M?si=O2ktVjctOxHrRP_R&amp;start=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </LazyLoadComponent>
                        </div>
                }

                <div className="futureBg position-relative py-1 py-lg-5 bg-image-parent">

                <LazyLoadImage src="assets/img/home/futureBg.webp" className="bg-image"/>


                    <Row className="justify-content-center justify-content-lg-start align-items-start mx-0 h-100">
                        <Col lg={4} md={11} sm={11} xs={11} className="mb-0 mb-md-5">
                            <div className="text-left px-3 px-lg-5 py-3 py-md-0 py-lg-5 mt-md-5 mt-lg-0">
                                <div className="d-flex flex-column">
                                    <div className="futureTitle text-left">
                                        <span className="d-block">{translate(props.lang, "揭秘马来西亚")}</span>
                                    </div>
                                    <div className="futureTitle text-left">
                                        <span className="d-block mb-4">{translate(props.lang, "第二家园的独特福利")}</span>
                                    </div>
                                </div>
                                <div className="">
                                    <span className="d-block mb-3 futureBgDesc">{translate(props.lang, "发现独特福利，马来西亚第二家园等你揭秘")}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center justify-content-lg-end align-items-center mx-0 h-100 futureSliderPos w-100">
                        <Col lg={8} md={11} sm={11} xs={11} className="mb-5 futureSliderBg">
                            <Carousel showStatus={false} infiniteLoop={true} showArrows={false} showThumbs={false} swipeable={false} renderArrowPrev={(clickHandler, hasPrev) => {
                                return (
                                    <div
                                        className={`carousel-img left`}
                                        onClick={clickHandler}

                                    >
                                        <LazyLoadComponent>
                                        <img src="assets/img/home/sliderPrev.webp" className="mx-auto prevImg" alt="prev" />
                                        </LazyLoadComponent>
                                    </div>
                                );
                            }}
                                renderArrowNext={(clickHandler, hasNext) => {
                                    return (
                                        <div
                                            className={`carousel-img right `}
                                            onClick={clickHandler}

                                        >
                                        <LazyLoadComponent>
                                            <img src="assets/img/home/sliderNext.webp" className=" mx-auto nextImg" alt="next" />
                                        </LazyLoadComponent>
                                        </div>
                                    );
                                }}

                            >

                                <div className="text-left px-4 px-md-5 py-4 py-md-5">
                                    <div className="futureSliderTitle text-left">
                                        <span className="d-block mb-4">{translate(props.lang, "对孩子未来的优势")}</span>
                                    </div>
                                    <div className="pb-5">
                                        <div className="d-flex justify-content-start align-items-start">
                                            <div>
                                                <div className="futurePoint mr-3 mr-lg-4 mt-1" />
                                            </div>
                                            <span className="d-block mb-4 futureBgPointDesc">{translate(props.lang, "马来西亚沿袭英国及欧美教育体系，教育水准和文凭均被欧美知名学府承认。子女按国民待遇就读当地学校，接受中英文国际化教育，费用低廉。可让孩子赢在起跑线上。")}</span>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-start">
                                            <div>
                                                <div className="futurePoint mr-3 mr-lg-4 mt-1" />
                                            </div>
                                            <span className="d-block mb-4 futureBgPointDesc">{translate(props.lang, "马来西亚与欧美国家实行”3+0* 和 ”双联课程”，学生可选择转移课程的方式转赴美、加、英、澳等国修读。可回避雅思门槛，签证也不需回国内办理，颁发英美加澳名校的毕业证书，而费用仅为直接留学的1/4。")}</span>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-start">
                                            <div>
                                                <div className="futurePoint mr-3 mr-lg-4 mt-1" />
                                            </div>
                                            <span className="d-block mb-4 futureBgPointDesc">{translate(props.lang, "低门槛获取华侨身份，可获免税、子女高考加分、领养第二子女等特别权益。家长不妨选择先移民后留学。")}</span>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-start">
                                            <div>
                                                <div className="futurePoint mr-3 mr-lg-4 mt-1" />
                                            </div>
                                            <span className="d-block mb-4 futureBgPointDesc">{translate(props.lang, "子女持第二家园签证于大马念大学，毕业后不需转换签证可获得工作许可。")}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-left px-4 px-md-5 py-4 py-md-5">
                                    <div className="futureSliderTitle text-left">
                                        <span className="d-block mb-4">{translate(props.lang, "老年幸福生活的乐园")}</span>
                                    </div>
                                    <div className="">
                                        <div className="d-flex justify-content-start align-items-start">
                                            <div>
                                                <div className="futurePoint mr-3 mr-lg-4 mt-1" />
                                            </div>
                                            <span className="d-block mb-4 futureBgPointDesc">{translate(props.lang, "马来西亚是亚洲最适合居住的国家，也是最受欢迎的移居国家。因为这里有友善温和的人民，较低的生活费用，完善的基础设施，迷人的自然环境，良好的投资环境和多元文化，并且拥有国际高水准的教育医疗系统。")}</span>

                                        </div>
                                        <div className="d-flex justify-content-start align-items-start">
                                            <div>
                                                <div className="futurePoint mr-3 mr-lg-4 mt-1" />
                                            </div>
                                            <span className="d-block mb-4 futureBgPointDesc">{translate(props.lang, "空气好，气温适宜(全年温度介于摄氏21度至32度之间)。无地震海啸火山等地质型自然灾害。")}</span>

                                        </div>
                                        <div className="d-flex justify-content-start align-items-start">
                                            <div>
                                                <div className="futurePoint mr-3 mr-lg-4 mt-1" />
                                            </div>
                                            <span className="d-block mb-4 futureBgPointDesc">{translate(props.lang, "中马两国文化背景相似，有近1/3华人的马来西亚，中文相当普及，受到众多国内退休养老人士的青睐。")}</span>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </Col>
                    </Row>
                </div>


            </div>
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(VideoSection);