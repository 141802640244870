import React from "react";
import { Col, Row } from "reactstrap";
import { HashLink } from 'react-router-hash-link';
import { translate } from 'utils/translate';
import { connect } from 'react-redux';

function HomeHeader(props) {
    return (
        <>
            <div className="" id="homeHeader">
                <div className="homeHeaderBg">

                    <picture>
                        <source srcSet="assets/img/home/headerBg.webp" media="(min-width: 1200px)" />
                        <source srcSet="/assets/img/home/headerMobileBg.webp" media="(min-width: 990px)" />
                        <img src="/assets/img/home/headerMobileBg.webp" className="bg-image" alt="header Bg" />
                    </picture>

                    <Row className="justify-content-center align-items-start mx-0 headerContentPos">
                        <Col lg={10} md={10} sm={10} xs={10} className="px-0">
                            <div className="headerTitle pb-1 pb-md-0">
                                <span className="d-block mb-1" style={{ width: "fit-content" }}>{translate(props.lang, "悠享阳光  尽在MM2H")}</span>
                                <span className="d-block" style={{ width: "fit-content" }}>{translate(props.lang, "您的第二家园  易马二家")}</span>
                            </div>

                            <div className="knowMoreBtnPos">
                                <HashLink smooth to='/index#mm2hPlan' className="hashLinkStyle knowMoreBtn">
                                    <span className="knowMoreBtnText">{translate(props.lang, "了解更多")}</span>
                                </HashLink>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="headerAbsoluteSection position-relative">
                    <Row className="justify-content-start align-items-start headerAbsolutePanel1 mx-0 w-100">
                        <Col lg={5} md={8} sm={12} xs={11} className="px-4 px-md-5 headerPanel1Bg w-100">
                            <div className="headerPanel1Content w-100 px-2 px-lg-5 py-4 py-lg-5">
                                <div className="">
                                    <div>
                                        <span className="headerPanel1Title d-block mb-1">{translate(props.lang, "马来西亚")}</span>
                                    </div>
                                    <div className="">
                                        <span className="headerPanel1Title d-block mb-3">{translate(props.lang, "第二家园计划")}</span>
                                    </div>
                                </div>


                                <div className="mt-4">
                                    <span className="headerPanel1Desc d-block mb-3">{translate(props.lang, "拥抱自由与幸福的新生活，畅享马来西亚第二家园计划，让幸福永续驻留")}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-end align-items-start headerAbsolutePanel2 mx-0 w-100">
                        <Col lg={8} md={11} sm={11} xs={11} className="px-1 px-md-5 headerPanel2Bg w-100">
                            <Row className="justify-content-center align-items-center mx-0 w-100">
                                <Col lg={9} md={12} sm={12} xs={12} className="">
                                    <Row className="justify-content-center align-items-center headerPanel2Content mx-0 w-100">
                                        <Col lg={4} md={4} sm={4} xs={4} className="text-center mb-4 px-1">
                                            <img src="assets/img/home/headerIcon1.webp" className="headerIconSize mb-4" alt="headerIcon" />
                                            <span className="headerPanel2Desc d-block mb-3">{translate(props.lang, "一人申请")}</span>
                                        </Col>
                                        <Col lg={4} md={4} sm={4} xs={4} className="text-center mb-4 px-1">
                                            <img src="assets/img/home/headerIcon2.webp" className="headerIconSize mb-4" alt="headerIcon" />
                                            <span className="headerPanel2Desc d-block mb-3">{translate(props.lang, "全家获签")}</span>
                                        </Col>
                                        <Col lg={4} md={4} sm={4} xs={4} className="text-center mb-4 px-1">
                                            <img src="assets/img/home/headerIcon3.webp" className="headerIconSize mb-4" alt="headerIcon" />
                                            <span className="headerPanel2Desc d-block mb-3">{translate(props.lang, "永久续签")}</span>
                                        </Col>

                                        <Col lg={4} md={4} sm={4} xs={4} className="text-center mb-4 px-1">
                                            <img src="assets/img/home/headerIcon4.webp" className="headerIconSize mb-4" alt="headerIcon" />
                                            <span className="headerPanel2Desc d-block mb-3">{translate(props.lang, "出入自由")}</span>
                                        </Col>
                                        <Col lg={4} md={4} sm={4} xs={4} className="text-center mb-4 px-1">
                                            <img src="assets/img/home/headerIcon5.webp" className="headerIconSize mb-4" alt="headerIcon" />
                                            <span className="headerPanel2Desc d-block mb-3">{translate(props.lang, "环境优越")}</span>
                                        </Col>
                                        <Col lg={4} md={4} sm={4} xs={4} className="text-center mb-4 px-1">
                                            <img src="assets/img/home/headerIcon6.webp" className="headerIconSize mb-4" alt="headerIcon" />
                                            <span className="headerPanel2Desc d-block mb-3">{translate(props.lang, "高福利")}</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(HomeHeader);