import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from "views/Home";

import "./assets/css/custom.scss";
import "./assets/css/home.scss";
import "./assets/css/aboutUs.scss";
import "./assets/css/longtermPass.scss";
import "./assets/css/property.scss";
import "./assets/css/propertyDetail.scss";
import "./assets/css/contactUs.scss";
import "./assets/css/application.scss";
import "./assets/css/application.scss";
import "./assets/css/school.scss";

const AboutUs = lazy(() => import("views/aboutUs/AboutUs"));
const ContactUs = lazy(() => import("views/contactUs/ContactUs"));
const LongtermPass = lazy(() => import("views/longtermPass/LongtermPass"));
const Property = lazy(() => import("views/property/Property"));
const PropertyDetails = lazy(() => import("views/property-details/PropertyDetails"));
const InternationalSchool = lazy(() => import("views/internationalSchool/InternationSchool"));
const SchoolDetails = lazy(() => import("views/school-details/SchoolDetails"));
const ApplicationFlow = lazy(() => import("views/applicationFlow/ApplicationFlow"));




function App() {

    // React.useEffect(() => {
    //     getIPLocation();
    // }, []);

    const getIPLocation = () => {
        fetch("http://ip-api.com/json/", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Content-Language": 'en',
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                localStorage.setItem('YMG_location', responseJson.country);
            }).catch(error => {
                console.log("Error:", error);
            });
    }

    return (
        <>
            <Switch>
                <Switch>

                    <Route path="/home" render={(props) => <Home {...props} />} />

                    <Route path="/about-us" render={(props) =>
                        <Suspense fallback={<div>Loading</div>}>
                            <AboutUs {...props} />
                        </Suspense>
                    } />

                    <Route path="/longterm-pass" render={(props) =>
                        <Suspense fallback={<div>Loading</div>}>
                            <LongtermPass {...props} />
                        </Suspense>} />


                    <Route path="/property" render={(props) => 
                    <Suspense fallback={<div>Loading</div>}>
                        <Property {...props} />
                    </Suspense>} />
                    
                    <Route path="/property-details" render={(props) => 
                    <Suspense fallback={<div>Loading</div>}>
                        <PropertyDetails {...props} />
                    </Suspense>} />
                    
                    <Route path="/contact-us" render={(props) => 
                    <Suspense fallback={<div>Loading</div>}>
                        <ContactUs {...props} />
                    </Suspense>} />
                    
                    <Route path="/education/international-schools" render={(props) => 
                    <Suspense fallback={<div>Loading</div>}>
                        <InternationalSchool {...props} />
                    </Suspense>} />
                    
                    <Route path="/education/international-schools-details" render={(props) => 
                    <Suspense fallback={<div>Loading</div>}>
                        <SchoolDetails {...props} />
                    </Suspense>} />
                    
                    <Route path="/education/application-flow" render={(props) => 
                    <Suspense fallback={<div>Loading</div>}>
                        <ApplicationFlow {...props} />
                    </Suspense>} />
                    

                    <Redirect from="/" to="/home" />
                </Switch>
            </Switch>
        </>
    );
}

export default App;
